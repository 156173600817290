import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { withAuth } from "./core/helpers/withAuth";
import { withPermission } from "./core/helpers/withPermission";
import { withSuspense } from "./core/helpers/withSuspense";
import { DashboardRouteUtil } from "./core/routes/dashboard.route";
import { CustomersRoutes, CitiesCountiesRoutes, authRoutes } from "./routes/index.routing";
import { PermissionsRoutes } from "./routes/permissions.routing";

const OrdersPage = React.lazy(() => import("./pages/dashboard/order/list"));

const OrderEditPage = React.lazy(() => import("./pages/dashboard/order/edit"));

const DashboardShowPage = React.lazy(() => import("./pages/dashboard/dashboard/show"));

const SuppliersPage = React.lazy(() => import("./pages/dashboard/supplier/list"));

const SupplierSectionsPage = React.lazy(() => import("./pages/dashboard/supplier/section/list"));

const CreateSupplierPage = React.lazy(() => import("./pages/dashboard/supplier/create"));

const EditSupplierPage = React.lazy(() => import("./pages/dashboard/supplier/edit"));

const WorkingHoursPage = React.lazy(() => import("./pages/dashboard/supplier/working-hours"));

const CreateItemPage = React.lazy(() => import("./pages/dashboard/supplier/items/create"));

const EditItemPage = React.lazy(() => import("./pages/dashboard/supplier/items/edit"));

const ItemsListPage = React.lazy(() => import("./pages/dashboard/supplier/items/list"));

const CustomersListPage = React.lazy(() => import("./pages/dashboard/supplier/customers/list"));

const SupplierBranches = React.lazy(() => import("./pages/dashboard/supplier/branches/list"));

const SupplierBranchCreateUpdate = React.lazy(
  () => import("./pages/dashboard/supplier/branches/create-edit-branch")
);

function AppRouting() {
  const dashboardRoutes: JSX.Element[] = [
    <Route
      exact
      key="dashboard"
      path={DashboardRouteUtil.getDashboardPath()}
      component={() => withAuth(withPermission("dashboard_view", withSuspense(DashboardShowPage)))}
    />,
    <Route
      exact
      key="orders"
      path={DashboardRouteUtil.getOrdersListPath()}
      component={() => withAuth(withSuspense(OrdersPage))}
    />,
    <Route
      exact
      key="order-edit"
      path={DashboardRouteUtil.getOrderEditPath()}
      component={() => withAuth(withSuspense(OrderEditPage))}
    />,
    <Route
      exact
      key="suppliers"
      path={DashboardRouteUtil.getSuppliersListPath()}
      component={() => withAuth(withSuspense(SuppliersPage))}
    />,

    <Route
      exact
      key="supplier-create"
      path={DashboardRouteUtil.getSupplierCreatePath()}
      component={() => withAuth(withSuspense(CreateSupplierPage))}
    />,
    <Route
      exact
      key="supplier-edit"
      path={DashboardRouteUtil.getSupplierEditPath()}
      component={() => withAuth(withSuspense(EditSupplierPage))}
    />,
    <Route
      exact
      key="supplier-branches"
      path={DashboardRouteUtil.getSupplierBranchesPath()}
      component={() => withAuth(withSuspense(SupplierBranches))}
    />,
    <Route
      exact
      key="supplier-working-hours"
      path={DashboardRouteUtil.getSupplierWorkingHoursPath()}
      component={() => withAuth(withSuspense(WorkingHoursPage))}
    />,
    <Route
      exact
      key="supplier-sections"
      path={DashboardRouteUtil.getSupplierSectionPath()}
      component={() => withAuth(withSuspense(SupplierSectionsPage))}
    />,
    <Route
      exact
      key="supplier-items-edit"
      path={DashboardRouteUtil.getSupplierItemsEditPath()}
      component={() => withAuth(withSuspense(EditItemPage))}
    />,
    <Route
      exact
      key="supplier-items-edit-2"
      path={DashboardRouteUtil.getSupplierBranchItemsEditPath()}
      component={() => withAuth(withSuspense(EditItemPage))}
    />,
    <Route
      exact
      key="supplier-items-create"
      path={DashboardRouteUtil.getSupplierItemsCreatePath()}
      component={() => withAuth(withSuspense(CreateItemPage))}
    />,
    <Route
      exact
      key="supplier-items-list"
      path={DashboardRouteUtil.getSupplierItemsListPath()}
      component={() => withAuth(withSuspense(ItemsListPage))}
    />,
    <Route
      exact
      key="supplier-customers-list"
      path={DashboardRouteUtil.getSupplierCustomersListPath()}
      component={() => withAuth(withSuspense(CustomersListPage))}
    />,
    <Route
      exact
      key="list-customers-details"
      path={DashboardRouteUtil.getCustomerDetailsPath()}
      component={() => withAuth(withSuspense(CustomersListPage))}
    />,
    <Route
      exact
      key="edit-customers-details"
      path={DashboardRouteUtil.getEditCustomerDetailsPath()}
      component={() => withAuth(withSuspense(CustomersListPage))}
    />,

    <Route
      exact
      key="create-customer"
      path={DashboardRouteUtil.getCreateBranchPath()}
      component={() => withAuth(withSuspense(SupplierBranchCreateUpdate))}
    />,
    <Route
      exact
      key="edit-customer"
      path={DashboardRouteUtil.getEditSupplierBranchesPath()}
      component={() => withAuth(withSuspense(SupplierBranchCreateUpdate))}
    />,
  ];

  const commonRoutes = [
    <Redirect path="*" to={DashboardRouteUtil.getDashboardPath()} exact key="redirect" />,
  ];

  const finalRoutes = [
    ...authRoutes,
    ...dashboardRoutes,
    // ...SellerBalance,
    ...PermissionsRoutes,
    ...CustomersRoutes,
    ...CitiesCountiesRoutes,
    ...commonRoutes,
  ];

  return (
    <React.Suspense fallback={<></>}>
      <Switch>{finalRoutes}</Switch>
    </React.Suspense>
  );
}

export default AppRouting;
