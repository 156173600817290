import React from "react";
import { Route } from "react-router-dom";
import { withAuth } from "../core/helpers/withAuth";
import { withSuspense } from "../core/helpers/withSuspense";
import { DashboardRouteUtil } from "../core/routes/dashboard.route";

const ListCustomersPage = React.lazy(() => import(`../pages/dashboard/customers/list`));
const CustomerCreateUpdate = React.lazy(() => import(`../pages/dashboard/customers/create-edit`));
const ListCustomersBranchesPage = React.lazy(
  () => import(`../pages/dashboard/customers/branches/list`)
);
const CustomerBranchCreateUpdate = React.lazy(
  () => import(`../pages/dashboard/customers/branches/create-edit-branch`)
);
export const CustomersRoutes: JSX.Element[] = [
  <Route
    exact
    key="list-customers"
    path={DashboardRouteUtil.getListCustomersPath()}
    component={() => withAuth(withSuspense(ListCustomersPage))}
  />,
  <Route
    exact
    key="create-customer"
    path={DashboardRouteUtil.getCustomerCreatePath()}
    component={() => withAuth(withSuspense(CustomerCreateUpdate))}
  />,
  <Route
    exact
    key="edit-customer"
    path={DashboardRouteUtil.getEditCustomer()}
    component={() => withAuth(withSuspense(CustomerCreateUpdate))}
  />,
  <Route
    exact
    key="customer-branches"
    path={DashboardRouteUtil.getCustomerBranchesPath()}
    component={() => withAuth(withSuspense(ListCustomersBranchesPage))}
  />,

  <Route
    exact
    key="customer-branches-create"
    path={DashboardRouteUtil.getCreateCustomerBranchPath()}
    component={() => withAuth(withSuspense(CustomerBranchCreateUpdate))}
  />,
  <Route
    exact
    key="customer-branches-edit"
    path={DashboardRouteUtil.getEditCustomerBranchesPath()}
    component={() => withAuth(withSuspense(CustomerBranchCreateUpdate))}
  />,
];
