import { Link, useLocation } from "react-router-dom";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { SideText } from "../../../core/helpers/style.helper";
import newLogo from "../../../assets/icons/Mazaj-b2b.svg";
import styles from "./style.module.scss";
import { DashboardRouteUtil } from "../../../core/routes/dashboard.route";
import { SidebarType } from "../../../core/types/sidebar.type";
import { Can } from "../../../components/can";
import { useEffect, useState } from "react";
import React from "react";

export type DashboardWrapperProps = {
  children: JSX.Element;
  withoutHeader?: boolean;
  withOverlay?: boolean;
  fullOverlay?: boolean;
  title?: string;
  additionalMenu?: JSX.Element;
};

export const AppWrapper = ({ children }: DashboardWrapperProps) => {
  const { t } = useTranslation();
  const [activePage, setActivePage] = useState("");

  useEffect(() => {
    const pathArray = window.location.pathname.split("/");
    if (pathArray[2]) {
      setActivePage(pathArray[2]);
    } else {
      setActivePage(pathArray[1]);
    }
  }, []);

  const list: SidebarType[] = [
    {
      label: "dashboard",
      link: DashboardRouteUtil.getDashboardPath(),
      icon: require(`../../../assets/icons/dashboard.svg`).default,
      isActive: activePage === "dashboard",
      module: "dashboard",
    },
    {
      label: "supplier",
      link: DashboardRouteUtil.getSuppliersListPath(),
      icon: require(`../../../assets/icons/sellers.svg`).default,
      isActive: activePage === "suppliers",
      module: "suppliers",
    },
    {
      label: "customers",
      link: DashboardRouteUtil.getListCustomersPath(),
      icon: require(`../../../assets/icons/customers.svg`).default,
      isActive: activePage === "customers",
      module: "customers",
    },
    {
      label: "orders",
      link: DashboardRouteUtil.getOrdersListPath(),
      icon: require(`../../../assets/icons/orders.svg`).default,
      isActive: activePage === "orders",
      module: "orders",
    },

    {
      label: "citiesManagement",
      link: DashboardRouteUtil.getCitiesListPath(),
      icon: require(`../../../assets/icons/cities.svg`).default,
      isActive: activePage === "location-management",
      module: "cities",
    },
    {
      label: "permissions",
      link: DashboardRouteUtil.getPermissionsModulePath(),
      icon: require(`../../../assets/icons/permissions.svg`).default,
      isActive: activePage === "permissions",
      module: "admins",
    },
    // {
    //   label: "seller.balance",
    //   link: DashboardRouteUtil.getSellerBalancePath(),
    //   icon: require(`../../../assets/icons/invoices.svg`).default,
    //   isActive: activePage === "seller-balance",
    //   module: "seller/balances",
    // },
  ];

  return (
    <>
      <Container className="d-block">
        <div className={"overlay-container"} />
      </Container>
      <div className={`${styles.wrapper} d-md-flex`}>
        <div className={`d-sm-block app-side-menu ${styles.sideBar}`}>
          <Container>
            <Row className="align-items-center justify-content-center">
              <Col className="m-2 text-center">
                <img src={newLogo} alt="Mazaj" width={100} className="large-mazaj-logo" />
                <img
                  src={newLogo}
                  alt="Mazaj"
                  width="48"
                  height="20"
                  className="small-mazaj-logo"
                />
              </Col>
            </Row>
            <Row className="align-items-center justify-content-center">
              {list.map((item: SidebarType) => (
                <Can do={"view"} on={item?.module} key={`app-route-${item.link}`}>
                  <Col xs="12">
                    <Link to={item.link} className="text-decoration-none">
                      <Button
                        className={`${styles.btnFont} ${
                          item.isActive ? "text-primary bg-primary-light" : ""
                        } w-100 py-2 d-flex align-items-center btn-primary-light mb-3`}
                        variant=""
                      >
                        <img src={item.icon} alt={item.label} />
                        <span className="mx-3 menu-item-label">
                          {t(`wrappers.dashboard.${item.label}`)}
                        </span>
                      </Button>
                    </Link>
                  </Col>
                </Can>
              ))}
            </Row>
            <div className={"overlay-container"} />
          </Container>
        </div>
        {children}
      </div>
    </>
  );
};
