const dashboardPrefix = "/dashboard";

export class DashboardRouteUtil {
  public static getLoginPath(): string {
    return "/";
  }

  public static getForgetPasswordPath(): string {
    return "/forget-password";
  }

  public static getSuccessSendPath(): string {
    return "/success-send";
  }

  public static getConfirmPasswordPath(): string {
    return "/password/reset";
  }

  public static getDashboardPath(): string {
    return `${dashboardPrefix}`;
  }

  public static getAccountSettingsPath(): string {
    return `${dashboardPrefix}/account-settings`;
  }

  public static getOrdersListPath(): string {
    return `${dashboardPrefix}/orders`;
  }

  public static getOrderEditPath(): string {
    return `${dashboardPrefix}/orders/:order_id/edit`;
  }

  public static getOrderEditUrl(order_id: string): string {
    return `${dashboardPrefix}/orders/${order_id}/edit`;
  }

  public static getSuppliersListPath(): string {
    return `${dashboardPrefix}/suppliers`;
  }

  public static getSupplierCreatePath(): string {
    return `${dashboardPrefix}/supplier/create`;
  }

  public static getSupplierWorkingHoursPath(): string {
    return `${dashboardPrefix}/suppliers/:supplier_id/working-hours`;
  }

  public static getSupplierWorkingHoursUrl(supplier_id: string): string {
    return `${dashboardPrefix}/suppliers/${supplier_id}/working-hours`;
  }

  public static getSupplierEditPath(): string {
    return `${dashboardPrefix}/suppliers/:supplier_id/edit`;
  }

  public static getSupplierEditUrl(supplier_id: string): string {
    return `${dashboardPrefix}/suppliers/${supplier_id}/edit`;
  }

  public static getSupplierSectionPath(): string {
    return `${dashboardPrefix}/suppliers/:supplier_id/sections`;
  }

  public static getSupplierSectionUrl(supplier_id: string): string {
    return `${dashboardPrefix}/suppliers/${supplier_id}/sections`;
  }

  public static getSupplierBranchesUrl(supplier_id: string): string {
    return `${dashboardPrefix}/suppliers/${supplier_id}/branches`;
  }

  public static getSupplierItemsPath(): string {
    return `${dashboardPrefix}/suppliers/:supplier_id/items`;
  }

  public static getSupplierItemsUrl(supplier_id: string): string {
    return `${dashboardPrefix}/suppliers/${supplier_id}/items`;
  }

  public static getSupplierItemsListPath(): string {
    return `${dashboardPrefix}/suppliers/:supplier_id/items`;
  }

  public static getSupplierItemsListUrl(supplier_id: string): string {
    return `${dashboardPrefix}/suppliers/${supplier_id}/items`;
  }

  public static getSupplierItemsCreatePath(): string {
    return `${dashboardPrefix}/suppliers/:supplier_id/items/create`;
  }

  public static getSupplierItemsCreateUrl(supplier_id: string): string {
    return `${dashboardPrefix}/suppliers/${supplier_id}/items/create`;
  }

  public static getSupplierCustomersListPath(): string {
    return `${dashboardPrefix}/suppliers/:supplier_id/customers`;
  }

  public static getSupplierCustomersListUrl(supplier_id: string): string {
    return `${dashboardPrefix}/suppliers/${supplier_id}/customers`;
  }

  public static getCustomerDetailsPath(): string {
    return `${dashboardPrefix}/suppliers/:supplier_id/customers/assign/`;
  }

  public static setCustomerDetailsPath(supplier_id): string {
    return `${dashboardPrefix}/suppliers/${supplier_id}/customers/assign`;
  }

  public static getEditCustomerDetailsPath(): string {
    return `${dashboardPrefix}/suppliers/:supplier_id/customers/assign/edit`;
  }

  public static setEditCustomerDetailsPath(supplier_id): string {
    return `${dashboardPrefix}/suppliers/${supplier_id}/customers/assign/edit`;
  }

  // Note: Duplicate is the same like create bug we add the duplicate_id as a query param
  public static getSupplierItemsDuplicateUrl(supplier_id: any, duplicate_id: any): string {
    return `${dashboardPrefix}/suppliers/${supplier_id}/items/create?duplicate_id=${duplicate_id}`;
  }

  public static getSupplierItemsEditPath(): string {
    return `${dashboardPrefix}/suppliers/:supplier_id/items/edit/:item_id`;
  }

  public static getSupplierBranchItemsEditPath(): string {
    return `${dashboardPrefix}/suppliers/:supplier_id/branches/:branch_id/items/edit/:item_id`;
  }

  public static getSupplierBranchItemsEditUrl(
    supplier_id: string,
    branch_id: string,
    item_id: string
  ): string {
    return `${dashboardPrefix}/suppliers/${supplier_id}/branches/${branch_id}/items/edit/${item_id}`;
  }

  public static getSupplierItemsEditUrl(supplier_id: string, item_id: any): string {
    return `${dashboardPrefix}/suppliers/${supplier_id}/items/edit/${item_id}`;
  }

  public static getSupplierBranchesPath(supplier_id?: string): string {
    return supplier_id
      ? `${dashboardPrefix}/suppliers/${supplier_id}/branches`
      : `${dashboardPrefix}/suppliers/:supplier_id/branches`;
  }

  public static getEditSupplierBranchesPath(supplier_id?: string, branchId?: string): string {
    return supplier_id
      ? `${dashboardPrefix}/suppliers/${supplier_id}/branches/${branchId}/edit`
      : `${dashboardPrefix}/suppliers/:supplier_id/branches/:branchId/edit`;
  }
  public static getCreateBranchPath(supplier_id?: string): string {
    return supplier_id
      ? `${dashboardPrefix}/suppliers/${supplier_id}/branches/create`
      : `${dashboardPrefix}/suppliers/:supplier_id/branches/create`;
  }

  // customer
  public static getListCustomersPath(): string {
    return `${dashboardPrefix}/customers`;
  }
  public static getCustomerCreatePath(): string {
    return `${dashboardPrefix}/customers/create`;
  }

  public static getEditCustomer(customerId?: string): string {
    return customerId
      ? `${dashboardPrefix}/customers/${customerId}/edit`
      : `${dashboardPrefix}/customers/:customerId/edit`;
  }

  public static getCustomerBranchesPath(customerId?: string): string {
    return customerId
      ? `${dashboardPrefix}/customers/${customerId}/branches`
      : `${dashboardPrefix}/customers/:customerId/branches`;
  }

  public static getEditCustomerBranchesPath(customerId?: string, branchId?: string): string {
    return customerId
      ? `${dashboardPrefix}/customers/${customerId}/branches/${branchId}/edit`
      : `${dashboardPrefix}/customers/:customerId/branches/:branchId/edit`;
  }
  public static getCreateCustomerBranchPath(customerId?: string): string {
    return customerId
      ? `${dashboardPrefix}/customers/${customerId}/branches/create`
      : `${dashboardPrefix}/customers/:customerId/branches/create`;
  }

  // Permission Module
  public static getPermissionsModulePath(): string {
    return `${dashboardPrefix}/permissions`;
  }

  // Users Module
  public static getCreateUserModulePath(): string {
    return `${dashboardPrefix}/permissions/users/create`;
  }

  // CITIES & Countries (location management)

  public static getCitiesListPath(): string {
    return `${dashboardPrefix}/location-management/cities`;
  }

  public static getCountriesListPath(): string {
    return `${dashboardPrefix}/location-management/countries`;
  }

  public static getCitiesCreatePath(): string {
    return `${dashboardPrefix}/location-management/cities/create`;
  }

  public static getCountriesCreatePath(): string {
    return `${dashboardPrefix}/location-management/countries/create`;
  }

  public static getCitiesEditPath(): string {
    return `${dashboardPrefix}/location-management/cities/:city_id/edit`;
  }

  public static getCitiesEditUrl(city_id: string): string {
    return `${dashboardPrefix}/location-management/cities/${city_id}/edit`;
  }

  public static getCountriesEditPath(): string {
    return `${dashboardPrefix}/location-management/countries/:country_id/edit`;
  }

  public static getCountriesEditUrl(city_id: string): string {
    return `${dashboardPrefix}/location-management/countries/${city_id}/edit`;
  }

  // seller balance
  public static getSellerBalancePath(): string {
    return `${dashboardPrefix}/seller-balance`;
  }
  // seller balance branches
  public static getSellerBalanceBranchesPath(): string {
    return `${dashboardPrefix}/seller-balance/branches`;
  }
}
