import axios, { AxiosError } from "axios";
import Storage from "../helpers/storage";
import { STORAGE_CONSTRAINT } from "../enums/storage";
import { DashboardRouteUtil } from "../routes/dashboard.route";

// Set config defaults when creating the instance
const axiosObject = axios.create();

axiosObject.interceptors.request.use(
  async function (config) {
    // Do something before request is sent
    if (Storage.get(STORAGE_CONSTRAINT.TOKEN)) {
      // await keycloak.updateToken(5);
      config = {
        ...config,
        headers: {
          Authorization: `Bearer ${Storage.get(STORAGE_CONSTRAINT.TOKEN)}`,
          ...config.headers,
        },
      };
    }
    return config;
  },
  function (error) {
    // Do something with request error
    console.error("error: interceptors", error);
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosObject.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error: AxiosError) {
    if (error.response!.status === 401) {
      localStorage.removeItem("userAuth");
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      window.location.href = DashboardRouteUtil.getLoginPath();
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export default axiosObject;
